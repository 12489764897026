import React, { useContext, useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCartShopping, faStar } from "@fortawesome/free-solid-svg-icons";
import { useNavigate, useParams } from "react-router-dom";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Breadcrumb from "../components/Breadcrumb";
import { AppContext } from "../context/AppContext";
import { useTranslation } from "react-i18next";

const IMAGE_URL = process.env.REACT_APP_IMAGE;

const SingleProduct = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [thumbnailIndex, setThumbnailIndex] = useState(0);
  const [selectedSize, setSelectedSize] = useState("");
  const [selectedColor, setSelectedColor] = useState("");
  const [isClickOnSizeCheck, setIsClickOnSizeCheck] = useState(false);
  const [isClickOnColor, setIsClickOnColor] = useState(false);
  const { products } = useContext(AppContext);

  const [product, setProduct] = useState({});

  useEffect(() => {
    const selectedProduct = products?.find((item) => item.id === id);
    setProduct(selectedProduct);
  }, [id, products]);

  const parseJSONString = (jsonString) => {
    try {
      return JSON.parse(jsonString);
    } catch (error) {
      // console.error("Error parsing JSON string:", error);
      return [];
    }
  };

  const handleNavigate = () => {
    window.scrollTo(0, 0);
    navigate(`/checkout/${product?.id}`);
  };

  const handleSizeChange = (size) => {
    setSelectedSize(size);
    setIsClickOnSizeCheck(true);
  };

  const handleClickColor = (color) => {
    setSelectedColor(color);
    setIsClickOnColor(true);
  };

  return (
    <>
      <Breadcrumb page={`single-product`} />
      <div id="singleProduct" className="container py-5">
        <div className="row">
          <div className="col-md-7">
            <div className="thumbnails-container">
              <Carousel
                selectedItem={thumbnailIndex}
                onChange={(index) => setThumbnailIndex(index)}
                showArrows={true}
              >
                {product?.images?.map((img, index) => (
                  <div key={index}>
                    <img
                      src={IMAGE_URL + "/" + img}
                      alt={`thumbnail-${index}`}
                    />
                  </div>
                ))}
              </Carousel>
            </div>
          </div>
          <div className="col-md-5">
            <h2 className="mb-3">{product.title?.en}</h2>{" "}
            <div className="d-flex align-items-center gap-1 mb-3">
              <FontAwesomeIcon icon={faStar} color={`#ffc045`} />
              <FontAwesomeIcon icon={faStar} color={`#ffc045`} />
              <FontAwesomeIcon icon={faStar} color={`#ffc045`} />
              <FontAwesomeIcon icon={faStar} color={`#ffc045`} />
              <FontAwesomeIcon icon={faStar} color={`#ffc045`} />
            </div>
            <p className="mb-3 price mb-3">${product.price}</p>
            <p className="text-muted mb-3">{product.category}</p>
            <p className="mb-3">
              {i18n.language === "en"
                ? product.description?.en
                : i18n.language === "fr"
                ? product.description?.fr
                : product.description?.ar}
            </p>
            <div className="mb-3 d-flex align-items-center gap-10">
              <p className="m-0">Sizes:</p>
              {parseJSONString(product?.sizes)?.map((size, index) => (
                <label
                  key={index}
                  className={`form-check-size ${
                    isClickOnSizeCheck && selectedSize === size.value
                      ? "active"
                      : ""
                  }`}
                  onClick={() => handleSizeChange(size.value)}
                >
                  <input
                    type="radio"
                    name="size"
                    className="form-check-size-input-hide"
                    value={size.value}
                    checked={selectedSize === size.value}
                    onChange={() => handleSizeChange(size.value)}
                  />
                  {size.label}
                </label>
              ))}
            </div>
            <div className="mb-3 d-flex align-items-center gap-10">
              <p className="m-0">Colors:</p>
              {product?.colors &&
                parseJSONString(product?.colors)?.map((color, index) => (
                  <label
                    key={index}
                    className={`box-shadow position-relative color-label ${
                      `bg-` + color.value.toLowerCase()
                    } ${
                      isClickOnColor && selectedColor === color.value
                        ? "active"
                        : ""
                    }`}
                    onClick={() => handleClickColor(color.value)}
                  >
                    <input
                      type="radio"
                      name="color"
                      value={color}
                      checked={selectedColor === color.value}
                    />
                  </label>
                ))}
            </div>
            <div className="mb-3 d-flex align-items-center">
              <p className="mb-0">Quantity:</p>
              <div className="cart-plus-minus-box">{product.stockQuantity}</div>
            </div>
            <button
              type="submit"
              className="btn-custom"
              onClick={handleNavigate}
            >
              <FontAwesomeIcon icon={faCartShopping} /> Checkout
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default SingleProduct;

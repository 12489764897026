import React, { Suspense, lazy, useContext, useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import "bootstrap/dist/css/bootstrap.css";
import "./scss/core.scss";
import "./scss/rtl-support.scss";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/free-mode";
import "swiper/css/thumbs";

import { useTranslation } from "react-i18next";
import "./i18n/i18n";
import { RTL_LANGUAGES } from "./i18n";
import SingleProduct from "./pages/SingleProduct";
import { AppContext } from "./context/AppContext";
import PolicyPage from "./pages/PolicyPage";
import TermsPage from "./pages/TermsPage";

const Navbar = lazy(() => import("./components/Navbar"));
const Header = lazy(() => import("./components/Header"));
const MobileTabs = lazy(() => import("./components/MobileTabs"));
const Footer = lazy(() => import("./components/Footer"));
const Loading = lazy(() => import("./components/Loader/Loader"));

const Home = lazy(() => import("./pages/Home"));
const PageNotFound = lazy(() => import("./pages/PageNotFound"));
const ProductsPage = lazy(() => import("./pages/ProductsPage"));
const ContactPage = lazy(() => import("./pages/ContactPage"));
const CheckoutPage = lazy(() => import("./pages/checkoutPage"));

const IMAGE_URL = process.env.REACT_APP_IMAGE;

const App = () => {
  const { i18n } = useTranslation();
  document.dir = i18n.dir();
  const { websiteInfo } = useContext(AppContext);

  useEffect(() => {
    if (RTL_LANGUAGES.includes(i18n.language)) {
      const bootstrapRtlLink = document.createElement("link");
      bootstrapRtlLink.rel = "stylesheet";
      bootstrapRtlLink.href =
        "https://cdn.rtlcss.com/bootstrap/v5.0.3/css/bootstrap.min.css";
      document.head.appendChild(bootstrapRtlLink);
      document.documentElement.lang = "ar";
      document.body.dir = "rtl";
      document.querySelectorAll(".fj-gallery").forEach((gallery) => {
        gallery.setAttribute("dir", "ltr");
      });
    } else {
      const bootstrapRtlLink = document.querySelector(
        'link[href="https://cdn.rtlcss.com/bootstrap/v5.0.3/css/bootstrap.min.css"]'
      );
      if (bootstrapRtlLink) {
        bootstrapRtlLink.remove();
      }
      document.body.dir = "ltr";
    }
  }, [i18n.language]);

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1200);
  }, []);

  const [globalInfo, setGlobalInfo] = useState({});

  useEffect(() => {
    setGlobalInfo({
      description: websiteInfo[0]?.description,
      address: websiteInfo[0]?.address,
      phone: websiteInfo[0]?.phone,
      mail: websiteInfo[0]?.mail,
      logoImage: websiteInfo[0]?.logoImage,
      miniLogoImage: websiteInfo[0]?.miniLogoImage,
      faviconImage: websiteInfo[0]?.faviconImage,
      mapUrl: websiteInfo[0]?.mapUrl,
    });
  }, [websiteInfo]);

  useEffect(() => {
    const getFavicon = async () => {
      try {
        websiteInfo.forEach((info) => {
          const faviconImage = info.faviconImage;
          const faviconLink = document.getElementById("favicon");
          if (faviconLink) {
            faviconLink.href = `${IMAGE_URL}/${faviconImage}`;
          }
        });
      } catch (error) {
        console.log(error?.response?.data?.message);
      }
    };

    getFavicon();
  }, [websiteInfo]);

  return (
    <Router>
      <Suspense fallback={<Loading />}>
        <Header />
        <Navbar globalInfo={globalInfo} />

        <Routes>
          <Route index element={<Home />} />
          <Route path="/products" element={<ProductsPage />} />
          <Route path="/products/:id" element={<SingleProduct />} />
          <Route
            path="/products/category/:category"
            element={<ProductsPage />}
          />

          <Route path="/contact" element={<ContactPage />} />
          <Route path="/checkout/:id" element={<CheckoutPage />} />

          <Route path="/policy" element={<PolicyPage />} />
          <Route path="/terms" element={<TermsPage />} />

          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </Suspense>
      <Footer />
      <MobileTabs />
    </Router>
  );
  // isLoading ? (
  //   <Loading />
  // ) : (

  // );
};

export default App;

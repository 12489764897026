import React, { createContext, useState, useEffect } from "react";
import { getAllProducts } from "../api/functions/products";
import { getAllCategories } from "../api/functions/categories";
import { getAllReviews } from "../api/functions/reviews";
import { getAllCompanies } from "../api/functions/company";
import { getHeroSlides } from "../api/functions/heroSlider";
import { getWebsiteInfo } from "../api/functions/websiteInfos";

import Loader from "../components/Loader/Loader";
import { getAllConditions } from "../api/functions/conditions";

export const AppContext = createContext();

export const AppContextProvider = ({ children }) => {
  const [sliders, setHeroSlider] = useState([]);
  const [products, setProducts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [reviews, setReviews] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [websiteInfo, setWebsiteInfo] = useState([]);
  const [conditions, setConditions] = useState([]);

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [
          productRes,
          categoryRes,
          sliderRes,
          reviewsRes,
          companiesRes,
          websiteInfoRes,
          conditionRes,
        ] = await Promise.all([
          getAllProducts(),
          getAllCategories(),
          getHeroSlides(),
          getAllReviews(),
          getAllCompanies(),
          getWebsiteInfo(),
          getAllConditions(),
        ]);

        if (productRes.status === 200) {
          setProducts(productRes.data.products);
          setCategories(categoryRes.data.categories);
          setHeroSlider(sliderRes.data.sliders);
          setReviews(reviewsRes.data.reviews);
          setCompanies(companiesRes.data.companies);
          setWebsiteInfo(websiteInfoRes.data.websiteInfo);
          setConditions(conditionRes.data.conditions);
        }
      } catch (error) {
        console.error(error?.response?.data?.message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  return isLoading ? (
    <Loader />
  ) : (
    <AppContext.Provider
      value={{
        sliders,
        setHeroSlider,
        products,
        setProducts,
        categories,
        setCategories,
        reviews,
        setReviews,
        companies,
        setCompanies,
        websiteInfo,
        setWebsiteInfo,
        conditions,
        setConditions,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../context/AppContext";
import Breadcrumb from "../components/Breadcrumb";

const PolicyPage = () => {
  const { conditions } = useContext(AppContext);

  const [content, setContent] = useState();

  useEffect(() => {
    setContent(conditions[0].privicyContent);
  }, []);

  const renderContent = () => {
    return (
      <div
        dangerouslySetInnerHTML={{
          __html: content,
        }}
      />
    );
  };

  return (
    <>
      <Breadcrumb page={`privicy-policy`} />
      <div className="container" dir="ltr">
        {renderContent()}
      </div>
    </>
  );
};

export default PolicyPage;
